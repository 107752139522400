import React from 'react';
import { Library } from 'lucide-react';
import Footer from './Footer';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <div className="min-h-screen bg-[#0f172a] flex flex-col">
      <header className="border-b border-gray-800">
        <div className="container mx-auto px-4 py-4">
          <a href="/" className="inline-flex items-center space-x-2 hover:opacity-80 transition-opacity">
            <Library className="w-8 h-8 text-blue-500" />
            <span className="text-2xl font-bold text-white">
              BookFinder<span className="text-blue-400">.io</span>
            </span>
          </a>
        </div>
      </header>
      {children}
      <Footer />
    </div>
  );
}