import React, { Suspense } from 'react';
import Spinner from './Spinner';

const HomePage = React.lazy(() => import('../pages/HomePage'));
const AboutPage = React.lazy(() => import('../pages/AboutPage'));
const LegalPage = React.lazy(() => import('../pages/LegalPage'));
const SelectionPage = React.lazy(() => import('../pages/SelectionPage'));
const GenresPage = React.lazy(() => import('../pages/GenresPage'));
const SciFiPage = React.lazy(() => import('../pages/SciFiPage'));

export default function Router() {
  const pathname = window.location.pathname;
  
  return (
    <Suspense fallback={
      <div className="flex-grow flex items-center justify-center">
        <Spinner className="w-12 h-12" />
      </div>
    }>
      {(() => {
        switch (pathname) {
          case '/':
            return <HomePage />;
          case '/about':
            return <AboutPage />;
          case '/mentions-legales':
            return <LegalPage />;
          case '/selection':
            return <SelectionPage />;
          case '/categories/genres':
            return <GenresPage />;
          case '/categories/science-fiction':
            return <SciFiPage />;
          default:
            return <HomePage />;
        }
      })()}
    </Suspense>
  );
}