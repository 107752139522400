import React from 'react';
import { Library, Heart } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-gray-900/50 border-t border-gray-800 mt-24">
      <div className="container mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {/* Logo et Description */}
          <div className="col-span-1 md:col-span-2">
            <a href="/" className="inline-flex items-center space-x-2 mb-4 hover:opacity-80 transition-opacity">
              <Library className="w-6 h-6 text-blue-500" />
              <span className="text-xl font-bold text-white">
                BookFinder<span className="text-blue-400">.io</span>
              </span>
            </a>
            <p className="text-gray-400 text-sm leading-relaxed mb-4">
              Découvrez votre prochaine lecture. Nous sommes là pour vous inspirer avec des recommandations 
              personnalisées, adaptées à vos goûts et à vos envies littéraires. Trouvez votre prochain 
              coup de cœur parmi des milliers de livres.
            </p>
          </div>

          {/* Liens */}
          <div>
            <ul className="space-y-2">
              <li>
                <a href="/" className="text-gray-400 hover:text-blue-400 text-sm transition-colors duration-200">
                  Accueil
                </a>
              </li>
              <li>
                <a href="/selection" className="text-gray-400 hover:text-blue-400 text-sm transition-colors duration-200">
                  Notre sélection de livres
                </a>
              </li>
              <li>
                <a href="/about" className="text-gray-400 hover:text-blue-400 text-sm transition-colors duration-200">
                  À propos
                </a>
              </li>
              <li>
                <a href="/mentions-legales" className="text-gray-400 hover:text-blue-400 text-sm transition-colors duration-200">
                  Mentions légales
                </a>
              </li>
            </ul>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-800 mt-12 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-gray-500 text-sm">
              © 2024 BookFinder.io. Tous droits réservés.
            </p>
            <div className="flex items-center space-x-1 text-gray-500 text-sm mt-4 md:mt-0">
              <span>Créé avec</span>
              <Heart className="w-4 h-4 text-red-500 mx-1" />
              <span>pour les passionnés de lecture</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}